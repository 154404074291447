<template>
  <div class="overflow-y-scroll">
    <dashboard-nav ref="modal" />
    <div
      class="overflow-y-scroll px-4 md:px-8 lg:pt-24 pb-6 xl:px-24 xxl:max-w-screen-xxl xxl:m-auto xxl:px-144 2xl:px-0 h-max"
    >
      <div class="flex flex-col lg:flex-row lg:justify-between mt-5">
        <h3 class="text-title text-5 lg:text-8 title">History</h3>
        <div class="lg:w-115">
          <div class="pt-5 pb-4 px-4 lg:px-3 bg-accent-2 rounded mt-4 lg:mt-0">
            <span class="refer--header text-title block lg:text-3.5"
              >Earn up to N10,000 monthly by referring your friends to
              Loanspot.</span
            >
            <!-- <span class="text-3 lg:text-3.5 text-sec-text mt-4 lg:mt-6 block"
              >You can share your referral link by copying and sending it or
              sharing to your social media.</span
            > -->
            <div class="lg:flex lg:items-center lg:mt-4">
              <div
                class="mt-4 lg:mt-0 lg:w-90 px-4 lg:pl-4 lg:pr-0 h-12 lg:h-10 bg-white rounded flex items-center"
              >
                <input
                  id="referral-code"
                  type="text"
                  :value="
                    'https://app.loanspot.africa/signup?referral=' +
                    profile.user.referralCode
                  "
                  class="bg-transparent w-full pr-5 text-3 text-primary focus:border-0 focus:outline-none"
                  readonly
                />
                <span
                  @click.prevent="copyReferral"
                  class="-ml-4 lg:hidden cursor-pointer"
                >
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.25 3.5V1.25C3.25 1.05109 3.32902 0.860322 3.46967 0.71967C3.61032 0.579018 3.80109 0.5 4 0.5H13C13.1989 0.5 13.3897 0.579018 13.5303 0.71967C13.671 0.860322 13.75 1.05109 13.75 1.25V11.75C13.75 11.9489 13.671 12.1397 13.5303 12.2803C13.3897 12.421 13.1989 12.5 13 12.5H10.75V14.75C10.75 15.164 10.4125 15.5 9.99475 15.5H1.00525C0.906345 15.5006 0.808298 15.4816 0.716742 15.4442C0.625186 15.4068 0.541925 15.3517 0.471744 15.282C0.401563 15.2123 0.345845 15.1294 0.307791 15.0381C0.269737 14.9468 0.250097 14.8489 0.25 14.75L0.25225 4.25C0.25225 3.836 0.58975 3.5 1.0075 3.5H3.25ZM1.75225 5L1.75 14H9.25V5H1.75225ZM4.75 3.5H10.75V11H12.25V2H4.75V3.5Z"
                      fill="#1656C1"
                    />
                  </svg>
                </span>
                <span
                  @click.prevent="copyReferral"
                  class="-ml-4 hidden lg:inline w-20 text-sm text-primary cursor-pointer copy-link"
                  >Copy link</span
                >
              </div>
              <div
                @click="showShare = !showShare"
                class="copy-link hidden lg:block ml-4 text-primary cursor-pointer"
              >
                Share
              </div>
            </div>
          </div>

          <div
            class="pt-5 pb-4 px-4 bg-accent-2 rounded mt-4 flex md:flex-col items-center justify-center lg:absolute lg:w-115"
            :class="showShare ? '' : 'lg:hidden'"
          >
            <span class="text-xs xxs:text-sm text-sec-text lg:hidden"
              >Share on</span
            >
            <div class="ml-6 md:ml-0 md:mt-6 lg:mt-0 flex lg:absolut">
              <a href="" class="">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="50" height="50" rx="4" fill="white" />
                  <path
                    d="M33.625 15.625H16.375C15.9602 15.625 15.625 15.9602 15.625 16.375V33.625C15.625 34.0398 15.9602 34.375 16.375 34.375H33.625C34.0398 34.375 34.375 34.0398 34.375 33.625V16.375C34.375 15.9602 34.0398 15.625 33.625 15.625ZM21.1867 31.6023H18.4047V22.6539H21.1867V31.6023ZM19.7969 21.4305C19.478 21.4305 19.1662 21.3359 18.901 21.1587C18.6358 20.9815 18.4292 20.7297 18.3071 20.435C18.1851 20.1404 18.1531 19.8162 18.2154 19.5034C18.2776 19.1906 18.4312 18.9033 18.6567 18.6778C18.8822 18.4522 19.1695 18.2987 19.4823 18.2365C19.7951 18.1742 20.1193 18.2062 20.414 18.3282C20.7086 18.4503 20.9604 18.6569 21.1376 18.9221C21.3148 19.1873 21.4094 19.499 21.4094 19.818C21.407 20.7086 20.6852 21.4305 19.7969 21.4305ZM31.6023 31.6023H28.8227V27.25C28.8227 26.2117 28.8039 24.8781 27.3766 24.8781C25.9305 24.8781 25.7078 26.0078 25.7078 27.175V31.6023H22.9305V22.6539H25.5977V23.8773H25.6352C26.0055 23.1742 26.9125 22.4312 28.2672 22.4312C31.0844 22.4312 31.6023 24.2852 31.6023 26.6945V31.6023Z"
                    fill="#1656C1"
                  />
                </svg>
              </a>
              <a href="" class="ml-4">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="50" height="50" rx="4" fill="white" />
                  <path
                    d="M34.7499 18.9602C34.0327 19.2695 33.2522 19.4922 32.4483 19.5789C33.283 19.0831 33.908 18.2997 34.2062 17.3758C33.4229 17.8417 32.5649 18.1684 31.6702 18.3414C31.2963 17.9416 30.844 17.6231 30.3415 17.4058C29.8391 17.1885 29.2973 17.0769 28.7499 17.0781C26.5351 17.0781 24.7538 18.8734 24.7538 21.0766C24.7538 21.3859 24.7913 21.6953 24.8522 21.993C21.5358 21.8195 18.578 20.2352 16.6116 17.8094C16.2533 18.4214 16.0656 19.1182 16.0679 19.8274C16.0679 21.2149 16.7733 22.4383 17.8491 23.1578C17.2151 23.1329 16.596 22.9586 16.0421 22.6492V22.6984C16.0421 24.6414 17.4155 26.2516 19.246 26.6219C18.9023 26.7112 18.5487 26.7568 18.1937 26.7578C17.9335 26.7578 17.6874 26.732 17.439 26.6969C17.9452 28.2813 19.4194 29.432 21.1749 29.4695C19.8015 30.5453 18.0812 31.1781 16.2132 31.1781C15.878 31.1781 15.5687 31.1664 15.2476 31.1289C17.0194 32.2656 19.1218 32.9219 21.3858 32.9219C28.7358 32.9219 32.7577 26.8328 32.7577 21.5477C32.7577 21.3742 32.7577 21.2008 32.746 21.0274C33.5241 20.4578 34.2062 19.7524 34.7499 18.9602Z"
                    fill="#1656C1"
                  />
                </svg>
              </a>
              <a href="" class="ml-4">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="50" height="50" rx="4" fill="white" />
                  <g clip-path="url(#clip0)">
                    <path
                      d="M18.6519 15C16.6289 15 15 16.6289 15 18.6519V31.3481C15 33.3711 16.6289 35 18.6519 35H25.5331V27.1813H23.4656V24.3663H25.5331V21.9613C25.5331 20.0718 26.7547 18.3369 29.5688 18.3369C30.7081 18.3369 31.5506 18.4463 31.5506 18.4463L31.4844 21.075C31.4844 21.075 30.6251 21.0669 29.6875 21.0669C28.6727 21.0669 28.51 21.5345 28.51 22.3107V24.3663H31.565L31.4319 27.1813H28.51V35H31.3481C33.3711 35 35 33.3711 35 31.3482V18.6519C35 16.6289 33.3711 15 31.3481 15H18.6519L18.6519 15Z"
                      fill="#1656C1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(15 15)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="loanHistory.length === 0 && isLoaded"
        class="mt-5 pt-4 border-t border-border"
      >
        You haven’t taken any loans yet. Check out loans with the best rates
        <span @click="showSearchModal" class="underline cursor-pointer click"
          >here.</span
        >
      </div>

      <div
        v-if="loanHistory.length > 0"
        class="mt-5 pt-4 border-t border-border"
      >
        <p class="text-sm md:text-lg title text-primary">Recent</p>
        <div v-for="(loan, i) in loanHistory" :key="i" class="mt-4">
          <p class="text-sm text-sec-text title">
            {{ dateFormat(loan.createdAt) }}
          </p>
          <div class="mt-2 rounded border border-border p-5">
            <!-- <div class=""> -->
            <div
              v-for="(lender, t) in loan.lenders"
              :key="t"
              class="border-border flex justify-between py-4"
              :class="[t !== (loan.lenders.length - 1) ? 'border-b' : '']"
            >
              <div
                class="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full"
              >
                <div>
                  <span class="text-sec-text text-3 lg:text-sm">Name</span>
                  <p class="text-title text-xs lg:text-base applied-value">
                    {{ lender.name }}
                  </p>
                </div>
                <div class="">
                  <span class="text-sec-text text-3 lg:text-sm"
                    >Type of loan</span
                  >
                  <p class="text-title text-xs lg:text-base applied-value">
                    {{ loan.loanType.name }}
                  </p>
                </div>
                <div class="">
                  <span class="text-sec-text text-3 lg:text-sm"
                    >Amount requested</span
                  >
                  <p class="text-title text-xs lg:text-base applied-value">
                    {{ formatLoanAmount(loan.amount) }}
                  </p>
                </div>
                <div class="">
                  <span class="text-sec-text text-3 lg:text-sm">Interest</span>
                  <p class="text-title text-xs lg:text-base applied-value">
                    {{ formatInterest(lender.minInterestRate, lender.maxInterestRate) }}
                  </p>
                </div>
                <div v-show="activeLoanBeingViewed === `${loan.id}-${lender.id}`" class="">
                  <span class="text-sec-text text-3 lg:text-sm"
                    >Purpose of loan</span
                  >
                  <p class="text-title text-xs lg:text-base applied-value">
                    {{ loan.purpose.title }}
                  </p>
                </div>
                <div v-show="activeLoanBeingViewed === `${loan.id}-${lender.id}`" class="">
                  <span class="text-sec-text text-3 lg:text-sm"
                    >Date of application</span
                  >
                  <p class="text-title text-xs lg:text-base applied-value">
                    {{ new Date(loan.createdAt).toLocaleDateString() }}
                  </p>
                </div>
                <div v-show="activeLoanBeingViewed === `${loan.id}-${lender.id}`" class="lg:col-start-4 lg:row-start-1">
                  <span class="text-sec-text text-3 lg:text-sm">Status</span>
                  <p
                    v-if="loan.status === 'Approved'"
                    :class="statusColor.approved"
                    class="text-xs applied-value h-6 w-20 rounded flex items-center justify-center"
                  >
                    {{ loan.status }}
                  </p>
                  <p
                    v-if="loan.status === 'pending'"
                    :class="statusColor.pending"
                    class="text-xs applied-value h-6 w-20 rounded flex items-center justify-center"
                  >
                    {{
                      loan.status.charAt(0).toUpperCase() + loan.status.slice(1)
                    }}
                  </p>
                  <p
                    v-if="loan.status === 'Declined'"
                    :class="statusColor.declined"
                    class="text-xs applied-value h-6 w-20 rounded flex items-center justify-center"
                  >
                    {{ loan.status }}
                  </p>
                </div>
              </div>
              <div
                @click.prevent="() => toggleViewLoan(`${loan.id}-${lender.id}`)"
                class="flex items-center justify-center rounded bg-accent-2 py-2 px-3 h-8 mt-7 lg:mt-0 cursor-pointer"
              >
                <span class="hidden lg:block text-xs text-primary tag">{{
                  activeLoanBeingViewed !== `${loan.id}-${lender.id}` ? "Expand" : "Collapse"
                }}</span>
                <span class="lg:ml-2">
                  <svg
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.50002 5.41669L1.08333 0L0 1.08333L5.41669 6.50002L5.41667 6.50005L6.5 7.58338L13 1.08339L11.9167 5.39911e-05L6.50002 5.41669Z"
                      fill="#1656C1"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import DashboardNav from "../../components/partials/dashboard-nav";
  import { ApiInstance } from "../../utils";

  export default {
    name: "History",
    components: {
      DashboardNav,
    },
    async mounted() {
      this.loanHistory = await this.getLoanHistory();
    },
    computed: {
      ...mapState({
        profile: (state) => state.profile,
      }),
    },
    data() {
      return {
        showShare: false,
        loanHistory: [],
        fullDetails: false,
        isLoaded: false,
        activeLoanBeingViewed: null,
        loanApplications: [
          {
            loanDate: "Thursday, 15th, 2021",
            appliedTo: [
              {
                lender: "South Crown Lenders",
                loanType: "Individual",
                amount: "NGN 500,000",
                interest: "7%",
                purpose: "Rent",
                dateApplied: "23/06/2020",
                status: "In view",
              },
              {
                lender: "South Crown Lenders",
                loanType: "Individual",
                amount: "NGN 500,000",
                interest: "7%",
                purpose: "Rent",
                dateApplied: "23/06/2020",
                status: "Approved",
              },
            ],
          },
          {
            loanDate: "Thursday, 15th, 2021",
            appliedTo: [
              {
                lender: "South Crown Lenders",
                loanType: "Individual",
                amount: "NGN 500,000",
                interest: "7%",
                purpose: "Rent",
                dateApplied: "23/06/2020",
                status: "In view",
              },
              {
                lender: "South Crown Lenders",
                loanType: "Individual",
                amount: "NGN 500,000",
                interest: "7%",
                purpose: "Rent",
                dateApplied: "23/06/2020",
                status: "Declined",
              },
              {
                lender: "South Crown Lenders",
                loanType: "Individual",
                amount: "NGN 500,000",
                interest: "7%",
                purpose: "Rent",
                dateApplied: "23/06/2020",
                status: "Approved",
              },
            ],
          },
        ],
        statusColor: {
          approved: "bg-status-approved-bg text-status-approved",
          pending: "bg-status-pending-bg text-status-pending",
          declined: "bg-status-denied-bg text-status-denied",
        },
      };
    },
    methods: {
      formatLoanAmount(number) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(number);
      },
      toggleViewLoan(viewed) {
        if (viewed === this.activeLoanBeingViewed) {
          this.activeLoanBeingViewed = null;
        } else this.activeLoanBeingViewed = viewed;
      },
      async getLoanHistory(checkLoaded = true) {
        try {
          const response = await ApiInstance.get("/loans");
          if (checkLoaded) {
            this.isLoaded = true;
          }
          return response.data?.data.items || [];
        } catch (error) {
          this.$notie.alert({
            type: "error",
            text: "Error while fetching loan history.",
            time: 3,
          });
        }
      },
      copyReferral() {
        let link = document.getElementById("referral-code");
        link.select();
        link.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.$notie.alert({
          type: "success",
          text: "Link copied to clipboard.",
          time: 3,
        });
      },
      check() {
        for (let i; i < this.loanHistory.length; i++) {
          console.log(this.loanHistory[i].createdAt);
        }
      },
      showSearchModal() {
        this.$refs.modal.searchLoanForm = true;
      },
      dateFormat(date) {
        const created = new Date(date).toDateString();
        const formatted = created.split(' ').slice(1);
        return formatted.join(" ");
      },
      formatInterest(min, max) {
        if(min === max) {
          return `${max}%`;
        } else {
          return `${min}% - ${max}%`
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
.title,
.refer--header,
.copy-link,
.applied-value,
.tag,
.click {
  font-family: SohneKraftig, sans-serif;
}
</style>